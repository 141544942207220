import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoaderComponent } from './loader/loader.component';
import { LoginComponent } from './auth/login/login.component';
import { AlertComponent } from './shared/alert/alert.component';
import { HttpClientModule } from '@angular/common/http';
import { MainComponent } from './layouts/main/main.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TrivasRestService } from './trivas-rest/trivas-rest.service';
import { AuthService } from './auth/auth.service';
import { ListOfItemsComponent } from './list-of-items/list-of-items.component';
import { NewOrderComponent } from './new-order/new-order.component';
import { StepsComponent } from './shared/steps/steps.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { OrderHeaderComponent } from './order-header/order-header.component';
import { OrdersService } from './shared/orders.service';
import { NewPackageFormComponent } from './order-header/new-package-form/new-package-form.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    LoginComponent,
    AlertComponent,
    MainComponent,
    ListOfItemsComponent,
    NewOrderComponent,
    StepsComponent,
    OrderHeaderComponent,
    NewPackageFormComponent,
    ConfirmationComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    HttpClientModule
  ],
  providers: [
    AuthService,
    TrivasRestService,
    OrdersService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
