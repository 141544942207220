import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {
  title = "zasilkovna-arlego";
  
  ngOnInit() {
    //console.warn("Pozor, eshop je nastaven staticky na eshop2!");
  }
}
