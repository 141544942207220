import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { AlertMessage, AlertMessageType } from 'src/app/shared/alert/alert-message';
import { OrdersService } from 'src/app/shared/orders.service';
import { TrivasRestService } from 'src/app/trivas-rest/trivas-rest.service';
import { PacketAttributes } from 'src/app/zasilkovna-rest/types';
import { ZasilkovnaService } from 'src/app/zasilkovna-rest/zasilkovna.service';
import { selectValues } from './npf-types';

declare const Packeta: any;
declare var $: any;

@Component({
  selector: 'new-package-form',
  templateUrl: './new-package-form.component.html',
  styleUrls: ['./new-package-form.component.less', '../../app.component.less']
})
export class NewPackageFormComponent implements OnInit {
  newPackageForm: FormGroup;
  packetAttributes: PacketAttributes;
  packet_widget: any;
  addressId: string;
  errors: AlertMessage[];
  order: string = '';
  customer: string = '';
  carrierTypes: string[] = ["Zásilkovna", "CZ - Zásilkovna domů", "SK - Zásilkovna domů", "Osobní odběr", 'GLS', 'DPD', 'PPL'];
  defaultPackageWeight: number = 3;
  currencies: selectValues[] = [{key:'CZK',value:'CZK'}, {key:'EUR',value:'EUR'}];
  provinces: selectValues[] = [{key:'ČR',value:'CZ'}, {key:'SK',value:'SK'}, {key:'AT',value:'AT'}, {key:'DE',value:'DE'}];
  zasilkovna: string = '';
  dobirka: string = '';
  carrier: number = 999999999;
  headerApproved: boolean;
  selectedCarrier: string = ""; // proměnná je platná jen při prvním načtení z constructoru
  selectedCurrency: string = "";
  selectedProvince: string = "";

  
  readonly isZasVisible = new BehaviorSubject(false);

  @Input() newDocNum: string;
  @Input() eshop: string;// = "test2";
  @Output() newPackage: EventEmitter<PacketAttributes> = new EventEmitter<PacketAttributes>();
  @ViewChild('zasilkovnaInput') inputZasilkovna: ElementRef;

  constructor(private fb: FormBuilder, public zas_rest: ZasilkovnaService, public trivas_rest: TrivasRestService, public oService: OrdersService) {    
    this.packetAttributes = this.oService.orderHeader;
    
    if (this.packetAttributes) {      
      switch (this.packetAttributes.addressId) {
        case 0:
          this.selectedCarrier = "Osobní odběr";
          break;

        case 1:
          this.selectedCarrier = "GLS";
          break;

        case 2:
          this.selectedCarrier = "DPD";
          break;

        case 3:
          this.selectedCarrier = "PPL";
          break;

        case 106:
          this.selectedCarrier = "CZ - Zásilkovna domů";
          break;

        case 131:
          this.selectedCarrier = "SK - Zásilkovna domů";
          break;

        default:
          this.selectedCarrier = "Zásilkovna";          
          break;
      }
    }
    
    this.newPackageForm = this.fb.group({
      number: [this.packetAttributes ? this.packetAttributes.number : '', Validators.required],
      name: [this.packetAttributes ? this.packetAttributes.name : '', Validators.required],
      //surname: ['', [Validators.required]],
      company: [this.packetAttributes ? this.packetAttributes.company : ''],
      email: [this.packetAttributes ? this.packetAttributes.email : ''],
      phone: [this.packetAttributes ? this.packetAttributes.phone : ''],
      carrierType: [''],
      street: [this.packetAttributes ? this.packetAttributes.street : ''],
      houseNumber: [this.packetAttributes ? this.packetAttributes.houseNumber : ''],
      city: [this.packetAttributes ? this.packetAttributes.city : ''],
      zip: [this.packetAttributes ? this.packetAttributes.zip : ''],
      addressIdText: [this.packetAttributes ? this.packetAttributes.addressIdText :''],
      addressId: [this.packetAttributes ? this.packetAttributes.addressId : 999999999],
      cod: [this.packetAttributes ? this.packetAttributes.cod : ''],
      currency: [this.packetAttributes ? this.packetAttributes.currency : 'CZK'],
      value: [this.packetAttributes ? this.packetAttributes.value : ''],
      //weight: [''],
      adultContent: [this.packetAttributes ? this.packetAttributes.adultContent : ''],
      //note: [''],
      province: [this.packetAttributes ? this.packetAttributes.province : 'CZ'],
      note: [this.packetAttributes ? this.packetAttributes.note : '']
    });
  }

  ngOnInit() {
    this.headerApproved = JSON.parse(localStorage.getItem("headerApproved")) || false;
    let carrierName: string = "Zásilkovna";
    if (this.packetAttributes)
      switch (this.packetAttributes.addressId) {
        case 0:
          carrierName = "Osobní odběr";
          break;

        case 1:
          carrierName = "GLS";
          break;

        case 2:
          carrierName = "DPD";
          break;

        case 3:
          carrierName = "PPL";
          break;

        case 106:
          carrierName = "CZ - Zásilkovna domů";
          break;

        case 131:
          carrierName = "SK - Zásilkovna domů";
          break;

        default:
          carrierName = "Zásilkovna";
          break;
      }
    this.carrierChanged(carrierName);
  }

  setIsZasVisible(newState: boolean): void {
    this.isZasVisible.next(newState);
  }
  getIsZasVisible(): Observable<boolean> {
    return this.isZasVisible.asObservable();
  }

  ngOnChanges() {
    if (this.newDocNum) {
      this.newPackageForm.patchValue({
        number: this.newDocNum
      })
    }
  }

  addressIdPick(data?: any): any {
    try {
      if (data) {
        this.setIsZasVisible(false);
        return data;
      };
    } catch (error) {
      console.error("ERR - addressIdPick: ", error);
    }
  }

  showPacketaWidget(): void {
    let opts: object = {
      appIdentity: 'trivas-1.0',
      //country: '',
      language: 'cs',
      //claimAssistant: '',
      //packetConsignment: '',
      //weight: '',
      primaryButtonColor: '#478cc0',
      backgroundColor: 'linear-gradient(90deg, #3d78a559, #399ba054)',//'linear-gradient(90deg, #77b7e7, #80dde2)',
      fontColor: '#3d78a5',
      fontFamily: '',
      cssUrl: ''
    };

    this.setIsZasVisible(!this.isZasVisible.value);
    if (this.isZasVisible.value) {
      this.packet_widget = Packeta.Widget.pick('67b364b070e681261095295785889284', data => {
        if (data) {
          this.newPackageForm.patchValue({
            addressId: data.id,
            addressIdText: data.name + ' (ID:' + data.id + ')'
          });
          if (this.errors) this.errors['address_id'] = null;
        }
        this.setIsZasVisible(false);
      }, opts, this.inputZasilkovna.nativeElement);
    } else {
      this.packet_widget = Packeta.Widget.close();
    }
  }

  validateForm(): boolean {
    this.errors = [];
    let vals = this.newPackageForm.value;
    let result: boolean = this.newPackageForm.valid && 
      (vals['email'] != '' || vals['phone'] != '') && 
      vals['street'] != '' && vals['houseNumber'] != '' && vals['city'] != '' && vals['zip'] != '';

    if(vals['number'] == '')
      this.errors['number'] = 'Vyplňte prosím číslo objednávky.';

    if(vals['name'] == '')
      this.errors['name'] = 'Vyplňte prosím jméno zákazníka.';
    
    if(vals['email'] == '' && vals['phone'] == '') {
      this.errors['email'] = 'Je nutné zadat alespoň nějaký kontakt, tedy e-mail nebo telefon.';
      this.errors['phone'] = 'Je nutné zadat alespoň nějaký kontakt, tedy e-mail nebo telefon.';
    }

    if(vals['street'] == '')
      this.errors['street'] = 'Vyplňte prosím ulici.';

    if(vals['houseNumber'] == '')
      this.errors['houseNumber'] = 'Vyplňte prosím číslo popisné.';

    if(vals['city'] == '')
      this.errors['city'] = 'Vyplňte prosím město.';

    if(vals['zip'] == '')
      this.errors['zip'] = 'Vyplňte prosím PSČ.';

    if(vals['province'] == '')
      this.errors['province'] = 'Vyberte prosím stát.';

    return result;
  }

  confirmPacket(): void {
    this.errors = [];
    let formData = this.newPackageForm.value;
    const isZasilkovna = (this.carrier > 3) && (this.carrier != 106) && (this.carrier != 131);

    this.order = formData.number;
    this.customer = formData.name;
    this.zasilkovna = formData.addressIdText;
    this.dobirka = formData.cod;

    //let correctProvince: string;

    /*switch (formData.province) {
      case "ČR": {
        correctProvince = "CZ";
        break;
      }
      case "SK": {
        correctProvince = "SK";
        break;
      }
    };*/

    if (!isZasilkovna) {  // NENÍ to Zásilkovna
      if(this.validateForm()) {
        this.packetAttributes = {
          addressId: this.carrier,
          eshop: this.eshop || "-nevyplneno-",
          name: formData.name,
          surname: formData.surname,
          number: formData.number,
          value: +formData.value.toString().replace(/,/g, '.'),
          adultContent: formData.adultContent,
          cod: +formData.cod.toString().replace(/,/g, '.'),
          currency: formData.currency,
          email: formData.email,
          phone: formData.phone,
          street: formData.street,
          houseNumber: formData.houseNumber,
          city: formData.city,
          zip: formData.zip,
          province: formData.province,
          note: formData.note,
          weight: this.defaultPackageWeight
        }
        this.sendPackage();
      }
    } else {  // JE to Zásilkovna
      if (this.eshop) {
        this.packetAttributes = {
          addressId: formData.addressId,
          addressIdText : formData.addressIdText,
          eshop: this.eshop,
          name: formData.name,
          surname: formData.surname,
          number: formData.number,
          value: +formData.value.toString().replace(/,/g, '.'),
          adultContent: formData.adultContent,
          cod: +formData.cod.toString().replace(/,/g, '.'),
          currency: formData.currency,
          email: formData.email,
          phone: formData.phone,
          note: formData.note,
          weight: this.defaultPackageWeight
        }
  
        this.zas_rest.isPacketAttributesValid(this.packetAttributes).then(result => {
          if (result === true) {
            this.sendPackage();
          } else {
            console.warn("-----------------------------\n! Zasilkovna nepřijala data !\n-----------------------------", result);
            this.errors = result;
          }
        });      
      } else
        alert("Není nastaven \"eshopid\" pro zásilkovnu");
    }
  }

  sendPackage(): void {
    //this.newPackageForm.reset();
    //this.newPackage.emit(this.packetAttributes);
    this.oService.orderHeader = this.packetAttributes;
    this.oService.saveHeaderData();
    this.headerApproved = true;
    localStorage.setItem('headerApproved', JSON.stringify(this.headerApproved));
  }

  inputChanged(inputName: string) {
    if (this.errors && ((inputName == 'phone' || inputName == 'email') && this.errors['email'] == ' Je nutné zadat alespoň nějaký kontakt, tedy e-mail nebo telefon.')) {
      this.errors['phone'] = null;
      this.errors['email'] = null;
    } else if (this.errors && this.errors[inputName]) {
      this.errors[inputName] = null;
    }
  }

  /*carrierChanged(newCarrier: string) {
    if (newCarrier == '1: CZ - Home Delivery' || newCarrier == '2: SK - Home Delivery') {
      $("#externalCarrier").collapse("show");
      $("#zasilkovna").collapse("hide");
    } else {
      $("#externalCarrier").collapse("hide");
      $("#zasilkovna").collapse("show");
    }
    this.carrier = this.carrierTypes.indexOf(newCarrier.substring(3));
  }*/

  carrierChanged(newCarrier: string) {
    if (newCarrier == 'Zásilkovna') {
      $("#externalCarrier").collapse("hide");
      $("#zasilkovna").collapse("show");
      $("#confirmButton").html("Zkontrolova údaje");
    } else {
      $("#externalCarrier").collapse("show");
      $("#zasilkovna").collapse("hide");
      $("#confirmButton").html("Potvrdit");
    }
    
    switch (this.carrierTypes.indexOf(newCarrier)) {
      case 0: { // Zásilkovna
        this.carrier = 999999999;
        break;
      }
      case 1: { // HD CZ
        this.carrier = 106;
        break;
      }
      case 2: { // HD SK
        this.carrier = 131;
        break;
      }
      case 3: { // Osobní odběr
        this.carrier = 0;
        break;
      }
      case 4: { // GLS
        this.carrier = 1;
        break;
      }
      case 5: { // DPD
        this.carrier = 2;
        break;
      }
      case 6: { // PPL
        this.carrier = 3;
      }
    }
    //this.carrier = this.carrierTypes.indexOf(newCarrier);
  }

  editOrderHeader() {
    this.headerApproved = false;
    this.oService.removeHeaderData();
    localStorage.setItem('headerApproved', JSON.stringify(this.headerApproved));
  }
}
