import { Component, OnInit } from '@angular/core';
import { Item } from '../list-of-items/list-item';
import { OrdersService } from '../shared/orders.service';
import { TrivasRestService } from '../trivas-rest/trivas-rest.service';
import { InsertOrder, ListOrder, VS015_OrdersListItems } from '../trivas-rest/types';
import { PacketAttributes } from '../zasilkovna-rest/types';

declare var $: any;

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.less']
})
export class ConfirmationComponent implements OnInit {

  readonly DocumentLibrary: string = "OPWEB";
  orderNumber: string = "";
  orderHeader: string[][] = [];
  orderItems: string[][] = [];

  constructor(public oService: OrdersService, public rService: TrivasRestService) {
  }

  ngOnInit() {
    this.fillHeader(this.oService.orderHeader);
    this.fillItems(this.oService.orderItems);
  }

  fillHeader(header: PacketAttributes) {
    let headerKeys: any;
    headerKeys = Object.keys(header);

    for(let key of headerKeys) {
      //this.orderHeader[key] = [];
      if (key == 'addressId') {
        if (header[key] == 106 ) {
          this.orderHeader.push([this.getHeaderName(key), 'CZ - Zásilkovna domů']);
        } else if (header[key] == 131 ) {
          this.orderHeader.push([this.getHeaderName(key), 'SK - Zásielkovňa domov']);
        } else if (header[key] == 0 ) {
          this.orderHeader.push([this.getHeaderName(key), 'Osobní odběr']);
        } else if (header[key] == 1 ) {
          this.orderHeader.push([this.getHeaderName(key), 'GLS']);
        } else if (header[key] == 2 ) {
          this.orderHeader.push([this.getHeaderName(key), 'DPD']);
        } else if (header[key] == 3 ) {
          this.orderHeader.push([this.getHeaderName(key), 'PPL']);
        } else {
          this.orderHeader.push([this.getHeaderName(key), 'Zásilkovna - '+header.addressIdText]);
        }
      } else if (key == 'number') {
        this.orderNumber = header[key];
      } else if (key == 'cod' || key == 'value') {
        this.orderHeader.push([this.getHeaderName(key), header[key]+' '+header['currency']]);
      } else if (key == 'adultContent') {
        if (header[key])
          this.orderHeader.push([this.getHeaderName(key), 'ANO'])
        else
          this.orderHeader.push([this.getHeaderName(key), 'NE']);
      } else if (!['eshop', 'addressIdText', 'currency'].includes(key) && header[key]) {
        this.orderHeader.push([this.getHeaderName(key), header[key]]);
      }
    }
  }

  fillItems(items: Item[]) {
    items.forEach(item => {
      this.orderItems.push([item.itemName, item.itemNumber, item.itemOrderAmount.toString()]);
    });
  }

  getHeaderName(varName: string): string {
    let result: string = '';
    switch (varName) {
      case 'number': {
        result = 'Objednávka';
        break;
      }
      case 'name': {
        result = 'Jméno';
        break;
      }
      case 'company': {
        result = 'Firma';
        break;
      }
      case 'email': {
        result = 'Email';
        break;
      }
      case 'phone': {
        result = 'Telefon';
        break;
      }
      case 'addressId': {
        result = 'Doprava';
        break;
      }
      case 'cod': {
        result = 'Dobírka';
        break;
      }
      case 'value': {
        result = 'Hodnota balíčku';
        break;
      }
      case 'street': {
        result = 'Ulice';
        break;
      }
      case 'houseNumber': {
        result = 'Číslo popisné';
        break;
      }
      case 'city': {
        result = 'Město';
        break;
      }
      case 'zip': {
        result = 'PSČ';
        break;
      }
      case 'adultContent': {
        result = 'Ověřit věk (18+)';
        break;
      }
      case 'province': {
        result = 'Stát';
        break;
      }
      case 'currency': {
        result = 'Měna';
        break;
      }
      case 'note': {
        result = "Poznámka";
        break;
      }
      case 'weight': {
        result = "Váha";
        break;
      }
      default: {
        result = varName;
        break;
      }
    }

    return result;
  }

  async confirmOrder(): Promise<void> {
    let newOrderItems: VS015_OrdersListItems[] = [];
    let id: number = 1;

    let todayD = new Date();
    let dd = String(todayD.getDate()).padStart(2, '0');
    let mm = String(todayD.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = todayD.getFullYear();
    let today: string = mm + '/' + dd + '/' + yyyy;

    this.oService.orderItems.forEach(item => {
      newOrderItems.push({
        Items_001: item.itemNumber,
        NameItems_001: item.itemName,
        Unit_999: '',
        Amount_017: +item.itemOrderAmount,
        //ID_015: id++,
        Price_003: '',
        VAT_003: ''
      });
    });

    /*await this.rService.returnNO().then(response => {
      console.log("ReturnNO response:", response)
      if(response) {
        orderNumber = response.DocumentNumber_999OS;
        orderLibrary = response.DocumentNumber_999OS;
      } else {
        console.error("Unable to get order and library number!");
      }
    });*/

    let newOrder: InsertOrder = ({
      DocumentLibrary_999OS: this.DocumentLibrary,
      DocumentNumber_999OS: this.oService.orderHeader.number,
      Company_006: this.oService.orderHeader.name + (this.oService.orderHeader.company != undefined ? this.oService.orderHeader.company : ''),
      ICO_006: '',
      Street_006: this.oService.orderHeader.street || '',
      HouseNumber_006: this.oService.orderHeader.houseNumber || '',
      City_006: this.oService.orderHeader.city || '',
      PostCode_006: this.oService.orderHeader.zip || '',
      IdentificationCountry_998: '',
      Company_009: this.oService.orderHeader.name + (this.oService.orderHeader.company != undefined ? this.oService.orderHeader.company : ''),
      Street_009: this.oService.orderHeader.street || '',
      HouseNumber_009: this.oService.orderHeader.houseNumber || '',
      City_009: this.oService.orderHeader.city || '',
      PostCode_009: this.oService.orderHeader.zip || '',
      IdentificationCountry_9981: this.oService.orderHeader.province,
      Currency_998OS: this.oService.orderHeader.currency || '',
      ConsumerOrder_014: this.oService.orderHeader.email || '',
      ContactPerson_014: '',
      Note_014: "Odesláno na sklad",
      Description_014: this.oService.orderHeader.note || '',
      /*Made_014: '1',
      MadeDate_014: today,*/
      Agreement_014: this.oService.orderHeader.phone,
      Transport_991: '',
      Settlement_999US: this.oService.orderHeader.cod ? 'Dobírka' : 'Převodem',
      Prepayment_014: this.oService.orderHeader.value,
      Discount_014: this.oService.orderHeader.cod,
      Centre_019: '0001',
      DocumentLibrary_999OSTransportOrder: this.oService.orderHeader.addressId.toString(),
      Items: newOrderItems
    });
  
    //console.log(newOrder);
    
    let result = await this.rService.createOrder(newOrder).then(isOrderCreated => {
      return isOrderCreated;
    }).catch(error => {
      console.error(error);
    });

    if (result) {
      this.rService.sendConfirmEmail(newOrder.DocumentLibrary_999OS, newOrder.DocumentNumber_999OS);
      this.oService.newOrder();
      $("#orderConfirmModal").modal();
    };
  }
}
