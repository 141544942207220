import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { AlertMessage, AlertMessageType } from '../../shared/alert/alert-message';
import { User } from '../user';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {

  signInForm: FormGroup;
  errMsg: AlertMessage;

  actualYear = new Date().getFullYear();

  //private localization: string = window.location.pathname.split('/')[1];

  constructor(public fb: FormBuilder, public authService: AuthService, public router: Router) {
    this.signInForm = this.fb.group({
      UserName: [''],
      Password: ['']
    });
  }

  ngOnInit() {
    if (this.authService.isLoggedIn === true) {
      this.router.navigate(['balicky']);
    }
  }

  async loginUser(): Promise<void> {
    //console.log("signinformvalue: ",this.signInForm.value);
    if (this.signInForm.value.UserName === "" && this.signInForm.value.Password === "") {
      console.warn("Cannot login, no credentials entered!");
    } else {
      await this.authService.signIn(this.signInForm.value).then((signedIn: string) => {
        if (signedIn !== "OK")
          this.errMsg = {
            text: signedIn,
            type: AlertMessageType.danger
          }
        else 
          this.router.navigate(['nova-objednavka/polozky']);
      });
    }
  }

}
