import { Injectable, isDevMode } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { NgxXml2jsonService } from 'ngx-xml2json';
import * as JSON2XML from 'json2xml';
import { AttributeFault, PacketAttributes, PacketAttributesValid, ZasilkovnaResponse } from './types';


@Injectable({
  providedIn: 'root'
})
export class ZasilkovnaService {

  private server_address: string = 'https://trivas.rest:10043/rfz';
  private zasilkovna_address: string = 'https://www.zasilkovna.cz/api/rest';
  private api_password: string = '6d53656932368962d14ad78d43a42365';
  /*private headers = new HttpHeaders()
    .set("Access-Control-Allow-Origin", "*")
    .set("Access-Control-Allow-Credentials", "true")
    .set("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept")
    .set("Referrer-Policy", "strict-origin-when-cross-origin")
    .set("Access-Control-Allow-Methods", "POST, GET, OPTIONS, PUT, DELETE")
    .set("Content-Type", "application/xml,application/x-www-form-urlencoded");*/

  constructor(public http: HttpClient, private xml2jsonService: NgxXml2jsonService) { }

  async isPacketAttributesValid(packetAttributes: PacketAttributes): Promise<any> {
    let Result: any = false;
    
    const packetAttributesValid: PacketAttributesValid = {
      apiPassword: this.api_password,
      packetAttributes: packetAttributes
    };

    const xmlObject: Object = {
      packetAttributesValid: packetAttributesValid
    };

    const xml: string = JSON2XML(xmlObject);

    const request = JSON.stringify({
      Url: this.zasilkovna_address,
      Params: xml
    });
    
    await this.serverPost(request).then(data => {
      let parser = new DOMParser;
      let xmlDoc = parser.parseFromString(data, 'text/xml');
      let result: Object = this.xml2jsonService.xmlToJson(xmlDoc);
      let zasilkovnaResponse = new ZasilkovnaResponse(result);
      
      if (zasilkovnaResponse.fault) {
        /*console.log("ZAS ERR - ", zasilkovnaResponse.string);
        console.log("--------------- details: -------------------");*/
        Result = [];
        if (zasilkovnaResponse.detail.length > 1) {
          zasilkovnaResponse.detail.forEach((fault: AttributeFault) => {
            //console.log(fault.name+': ', fault.fault);
            Result[fault.name] = fault.fault.substr(fault.fault.indexOf(':') + 1, fault.fault.length - 1);
          });
        }
        else {
          //console.log(zasilkovnaResponse.detail);
          Result[zasilkovnaResponse.detail.name] = zasilkovnaResponse.detail.fault.substr(zasilkovnaResponse.detail.fault.indexOf(':') + 1, zasilkovnaResponse.detail.fault.length - 1);;
        }
      } else if (zasilkovnaResponse.status === 'ok') {
        Result = true;
      }
    },(err: any) => {
      if (err instanceof(HttpErrorResponse))
        console.log("REST ERR: ", err.message);
    });
    
    return Result;
  };

  async createPackage(packetAttributes: PacketAttributes): Promise<any> {
    let Result: any = false;
    
    const createPacket: PacketAttributesValid = {
      apiPassword: this.api_password,
      packetAttributes: packetAttributes
    };

    const xmlObject: Object = {
      createPacket: createPacket
    }

    const xml: string = JSON2XML(xmlObject);

    const request = JSON.stringify({
      Url: this.zasilkovna_address,
      Params: xml
    });

    await this.serverPost(request).then(data => {
      let parser = new DOMParser;
      let xmlDoc = parser.parseFromString(data, 'text/xml');
      let result: Object = this.xml2jsonService.xmlToJson(xmlDoc);
      let zasilkovnaResponse = new ZasilkovnaResponse(result);
      
      if (zasilkovnaResponse.fault) {
        /*console.log("ZAS ERR - ", zasilkovnaResponse.string);
        console.log("--------------- details: -------------------");*/
        Result = [];
        if (zasilkovnaResponse.detail.length > 1) {
          zasilkovnaResponse.detail.forEach((fault: AttributeFault) => {
            //console.log(fault.name+': ', fault.fault);
            Result[fault.name] = fault.fault.substr(fault.fault.indexOf(':') + 1, fault.fault.length - 1);
          });
        }
        else {
          //console.log(zasilkovnaResponse.detail);
          Result[zasilkovnaResponse.detail.name] = zasilkovnaResponse.detail.fault.substr(zasilkovnaResponse.detail.fault.indexOf(':') + 1, zasilkovnaResponse.detail.fault.length - 1);;
        }
      } else if (zasilkovnaResponse.status === 'ok') {
        Result = zasilkovnaResponse.result.id;
      }
    },(err: any) => {
      if (err instanceof(HttpErrorResponse))
        console.log("REST ERR: ", err.message);
    });

    return Result;
  }
  
  serverPost(request: Object): Promise<any> {
    return this.http.post(this.server_address, request, { responseType: 'text' }).toPromise();
  }

  GetPacketLabelPDF(packetId: string, format?: string): void {
    const packetLabelPdf: Object = {
      apiPassword: this.api_password,
      packetId: packetId,
      format: format || 'A7 on A7',
      offset: 0
    };

    const xmlObject: Object = {
      packetLabelPdf: packetLabelPdf
    };
    
    const xml: string = JSON2XML(xmlObject);

    const request = JSON.stringify({
      Url: this.zasilkovna_address,
      Params: xml
    });

    this.http.post(this.server_address, request, { responseType: 'text' }).subscribe(data => {
      let parser = new DOMParser;
      let enc = new TextEncoder();

      let xmlDoc = parser.parseFromString(data, 'text/xml');
      let result: Object = this.xml2jsonService.xmlToJson(xmlDoc);
      let zasilkovnaResponse = new ZasilkovnaResponse(result);
      let pdfText = zasilkovnaResponse.result;
      /*let pdfUint8Array = enc.encode(pdfText);
      let pdfArrayBuffer = pdfUint8Array.buffer;
      console.log("text:", pdfText);
      console.log("arrayBuffer:", pdfArrayBuffer);

      this.downLoadFile(pdfArrayBuffer, 'text/plain');
      const url = URL.createObjectURL(blob);
      var a         = document.createElement('a');
      a.href        = url; 
      a.target      = '_blank';
      a.download    = 'stitek.pdf';
      document.body.appendChild(a);
      a.click();*/
      let file = this.readBinaryFile(pdfText, 'application/pdf', 'stitek.pdf');
      this.downLoadFile(file, 'application/pdf');
    });
  }

  downLoadFile(data: any, type: string) {
    let blob = new Blob([data], { type: type});
    let url = window.URL.createObjectURL(blob);
    let pwa = window.open(url);
    if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
        alert( 'Deaktivujte prosím blokování automaticky otevíraných oken a zkuste to znovu.');
    }
  }

  readBinaryFile(base64: string, type: string, name: string) {
    const data = atob(base64);
    const array = new Uint8Array(data.length);
    for (let i = 0; i < data.length; i++) {
        array[i] = data.charCodeAt(i);
    }
    const file = new File([array], name, { type });
    return file;
  }
  
  /*private str2ab(str) {
    var buf = new ArrayBuffer(str.length * 2); // 2 bytes for each char
    var bufView = new Uint16Array(buf);
    for (var i = 0, strLen = str.length; i < strLen; i++) {
      bufView[i] = str.charCodeAt(i);
    }
    return buf;
  }*/
}
