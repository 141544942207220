export interface PacketAttributes {
    id?: number,
    number: string,
    name: string,
    surname: string,
    company?: string,
    email?: string,
    phone?: string,
    addressId: number,
    addressIdText?: string,
    currency?: string,
    cod?: number,
    value: number,
    weight: number,
    deliverOn?: Date,
    eshop: string,
    adultContent?: boolean,
    note?: string,
    street?: string,
    houseNumber?: string,
    city?: string,
    province?: string,
    zip?: string,
    carrierService?: string,
    customerBarcode?: string,
    carrierPickupPoint?: string,
    customsDeclaration?: string,
    size?: number,
    attributes?: string,
    items?: string
};

export interface PacketAttributesValid {
    apiPassword: string;
    packetAttributes: PacketAttributes;
}

export interface AttributeFault {
    name: string,
    fault: string
}

export interface PacketAttributesFault {
    packetAttributesFault: AttributeFault[];
} 

export class ZasilkovnaResponse {
    status: string;
    fault?: string;
    string?: string;
    result?: any;
    detail?: any;

    constructor(data: any) {
        this.status =   data['response']['status'];
        if (this.status === 'fault') {
            this.detail = data['response']['detail']['attributes']['fault'];
            this.fault =    data['response']['fault'];
            this.string =   data['response']['string'];
        }
        this.result = data['response']['result'] || null;
    }
}

export interface PacketIdDetail {
    id: number,
    barcode: string,
    barcodeText: string
}