import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { MainComponent } from './layouts/main/main.component';
import { AuthGuard } from './auth/auth-guard.guard';
import { ListOfItemsComponent } from './list-of-items/list-of-items.component';
import { NewOrderComponent } from './new-order/new-order.component';
import { OrderHeaderComponent } from './order-header/order-header.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';


const routes: Routes = [
  // Site routes
  { 
    path: '', 
    component: MainComponent,
    children: [
      { path: '', redirectTo: 'nova-objednavka/polozky', pathMatch: 'full' },
      {
        path: 'nova-objednavka',
        component: NewOrderComponent,
        children: [
          { path: 'polozky', component: ListOfItemsComponent },
          { path: 'hlavicka', component: OrderHeaderComponent },
          { path: 'potvrzeni', component: ConfirmationComponent }
        ]
      },
      //{ path: 'packages', redirectTo: 'balicky' },
    ],
    canActivate: [AuthGuard]
  },
  // no layout routes
  { path: 'login', component: LoginComponent },
  // otherwise redirect to home
  { path: '**', redirectTo: 'nova-objednavka' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
