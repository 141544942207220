import { Component, OnInit, Input } from '@angular/core';
import { AlertMessage } from './alert-message';

@Component({
  selector: 'alert-message',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.less']
})
export class AlertComponent implements OnInit {

  @Input() message: AlertMessage;
  
  constructor() { }

  ngOnInit() {
  }

  dismiss(): void {
    this.message = null;
  }

}
