import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/auth/user';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.less', '../../app.component.less']
})
export class MainComponent implements OnInit {

  //localization: string = window.location.pathname.split('/')[1];
  ActualUser: User;
  ActiveEshop: String;
  TopMenu_Dropdown_Visible = true;
  TopMenuItems: String[] = [];

  constructor(public authService: AuthService) {
    this.ActualUser = this.authService.getUser();
  }

  ngOnInit() {
  }

  logout() {
    this.authService.doLogout();
  }

}
