import { Injectable } from '@angular/core';
import { User } from './user';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AvailableEshop, returnON } from '../trivas-rest/types';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { 

  }

  async signIn(user: User): Promise<string> {
    let request: string;

    let result: string = "Přihlášení se nezdařilo!";
    /*let databases: AvailableEshop[];
    let eshop: string;*/

    request = JSON.stringify({
      SqlServer: '92.62.228.244',
      UserName: user.UserName,
      Password: user.Password
    });
    
    let databases = await this.getAvailableDatabases(request);

    if (databases) {
      request = JSON.stringify({
        SqlServer: '92.62.228.244',
        DatabaseName: databases[0].DBNAME,
        UserName: user.UserName,
        Password: user.Password
      });
      let returnNo = await this.getZasilkovnaEshopName(request)
  
      if (returnNo.WWW_006) {
        user.Database = databases[0].DBNAME;
        user.ZasilkovnaEshop = returnNo.WWW_006;
        localStorage.setItem('user', JSON.stringify(user));
        result = "OK";
      } else if (user.UserName == "polivkam") {
        user.Database = databases[0].DBNAME;
        localStorage.setItem('user', JSON.stringify(user));
        result = "OK";
      } else {
        result = "Pro tento účet není nastaveno jméno do zásilkovny!";
      }
    } else {
      result = "Špatné přihlašovací údaje!";
    }
    
    /*this.http.post(`https://trivas.rest:10043/availabledatabases`, request).subscribe((response: AvailableEshop[]) => {
      result = false;
      if (response !== null) {
        user.Database = response[0].DBNAME;
        localStorage.setItem('user', JSON.stringify(user));
        result = true;
      }
    });*/

    /*this.http.post(`https://trivas.rest:10042/returnON`, request).subscribe((res: returnON) => {
      result = false;
      if (res !== null) {
        user.ZasilkovnaEshop = res.WWW_006;
        localStorage.setItem('user', JSON.stringify(user));
        //result.next(true);
        //console.log("TRUE");
      }
    });*/
    
    return result;
  }

  async getAvailableDatabases(request: string): Promise<AvailableEshop[]> {
    //let result: AvailableEshop[];
    
    return await this.http.post<AvailableEshop[]>(`https://trivas.rest:10043/availabledatabases`, request).toPromise();
    /*this.http.post(`https://trivas.rest:10043/availabledatabases`, request).subscribe((response: AvailableEshop[]) => {
      if (response !== null) {
        result = response;
      }
    });*/

    //return result;
  }

  async getZasilkovnaEshopName(request: string): Promise<returnON> {
    //let result: string;

    return await this.http.post<returnON>(`https://trivas.rest:10042/returnON`, request).toPromise();
    /*this.http.post(`https://trivas.rest:10042/returnON`, request).subscribe((res: returnON) => {
      if (res !== null) {
        result = res.WWW_006;
      }
    });

    return result;*/
  }

  getUser(): User {
    let user: User = JSON.parse(localStorage.getItem('user'));

    return user;
  }

  doLogout(): void {
    let user: User = this.getUser();

    if (user !== null) {
      localStorage.clear();
    }
  }

  get isLoggedIn(): boolean {
    let user: User = JSON.parse(localStorage.getItem('user'));

    return (user !== null) ? true : false;
  }
}
