import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import * as myFaIcons from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.less', './../../../assets/css/step.min.css']
})
export class StepsComponent implements OnInit {

  @Input() steps: any[][];

  faIcons = myFaIcons;
  
  constructor() { }

  ngOnInit() {
    for (let i = 0; i < this.steps.length; i++) {
      this.steps[i][0] = this.faIcons[this.steps[i][0]];
    }
  }
}
