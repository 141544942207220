import { Component, OnInit } from '@angular/core';
import { OrdersService } from '../shared/orders.service';

@Component({
  selector: 'app-new-order',
  templateUrl: './new-order.component.html',
  styleUrls: ['./new-order.component.less']
})
export class NewOrderComponent implements OnInit {

  steps: any[][] = [];

  constructor(public oService: OrdersService) { }

  ngOnInit() {
    this.oService.steps.subscribe(steps => {
      this.steps = steps;
    });
  }
}
