import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Item } from './list-item';
import { faPlus, faMinus, faTrash, faBox } from '@fortawesome/free-solid-svg-icons';
import { OrdersService } from '../shared/orders.service';
import { TrivasRestService } from '../trivas-rest/trivas-rest.service';
declare var $: any;

@Component({
  selector: 'list-of-items',
  templateUrl: './list-of-items.component.html',
  styleUrls: ['./list-of-items.component.less', '../app.component.less']
})
export class ListOfItemsComponent implements OnInit {

  @Output() isItems = new EventEmitter<number>();

  iBox = faBox;
  iPlus = faPlus;
  iMinus = faMinus;
  iTrash = faTrash;
  stockItems: Item[] = [];
  
  constructor(public oService: OrdersService, public rService: TrivasRestService) { }

  ngOnInit() {
    this.rService.getItems().subscribe(data => {
      this.stockItems = data;
    });
  }

  onSearchChange(searchValue: string): void {  
    this.oService.onSearchChange(searchValue);
  }

  /* Přesunuto do orders service
    private isItemInList(itemNumber: string): boolean {
    let result: boolean = false;

    if (this.orderItems.length > 0)
      this.orderItems.forEach(item => {
        if (item.itemNumber == itemNumber)
          result = true;
      });
    
    return result;
  }

  private getItemIndex(itemNumber: string): number {
    let result: number = -1;

    if(this.orderItems.length > 0)
      for(let i = 0; i < this.orderItems.length; i++) 
        if (this.orderItems[i].itemNumber == itemNumber)
          result = i;

    return result;
  }*/
  
  /* Přesunuto do orders service */
  odAddItem(newItem: Item) {
    this.oService.AddItem(newItem);
    this.isItems.emit(1);
  }

  onDecreaseItemAmount(listItem: Item) {
    if (listItem.itemOrderAmount > 0)
      listItem.itemOrderAmount--;
  }

  onIncreaseItemAmount(listItem: Item) {
    if (listItem.itemOrderAmount < listItem.itemStockAmoun)
      listItem.itemOrderAmount++;
  }

  /* Přesunuto do orders service */
  odRemoveItem(listItemRow: number) {
    this.oService.odRemoveItem(listItemRow);
    if (this.oService.orderItems.length <= 0)
      this.isItems.emit(0);
  }

  onItemsChange(maxValue: number, newValue: any): number {
    let result: number = 0;

    if (Number(newValue))
      if (newValue > maxValue)
      result = maxValue
      else if (newValue > 0)
        result = newValue
      else
        result = 0;
    else
      result = 0;

    return result;
  }
}
