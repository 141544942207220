import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-order-header',
  templateUrl: './order-header.component.html',
  styleUrls: ['./order-header.component.less']
})
export class OrderHeaderComponent implements OnInit {

  eshop: string;
  
  constructor(oUser: AuthService) {
    this.eshop = oUser.getUser().ZasilkovnaEshop;
  }

  ngOnInit() {
  }

}
