import { stringify } from '@angular/compiler/src/util';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Item } from '../list-of-items/list-item';
import { PacketAttributes } from '../zasilkovna-rest/types';
declare var $: any;

@Injectable({
  providedIn: 'root'
})

export class OrdersService {

  public orderItems: Item[] = [];
  public orderHeader: PacketAttributes = null;
  public steps = new BehaviorSubject(null);
  
  constructor(public router: Router) {
    let steps: any[][] = [];
    steps.push(['faBoxes', 'Položky', 'Vyberte položky objednávky', 'active', 'nova-objednavka/polozky']);
    steps.push(['faAddressCard', 'Objednávka', 'Vyplňte hlavičku objednávky', 'disabled', 'nova-objednavka/hlavicka']);
    steps.push(['faTasks', 'Potvrzení', 'Pokud vše souhlasí, potvrďte objednávku', 'disabled', 'nova-objednavka/potvrzeni']);
    this.steps.next(steps);

    this.loadData();
  }
  
  loadData() {
    //console.log(localStorage.getItem('orderItems'));
    if (this.orderItems.length == 0) {
      this.orderItems = JSON.parse(localStorage.getItem('IOorderItems')) || [];
    }

    if (this.orderHeader == null) {
      this.orderHeader = JSON.parse(localStorage.getItem("IOrderHeader")) || null;
    }

    if (this.orderItems.length > 0) {
      let steps = this.steps.getValue();
      steps[0][3] = 'completed';
      steps[1][3] = '';
      if (this.orderHeader != null) {
        steps[1][3] = 'completed';
        steps[2][3] = '';
      }
      this.steps.next(steps);
    }
  }
  
  saveHeaderData() {
    if (this.orderHeader) {
      localStorage.setItem('IOrderHeader', JSON.stringify(this.orderHeader));
      let steps = this.steps.getValue();
      steps[1][3] = 'completed'
      steps[2][3] = 'active';
      this.steps.next(steps);
      this.router.navigate(["/nova-objednavka/potvrzeni"]);
    }
  }

  removeHeaderData() {
    localStorage.removeItem('headerApproved');
    localStorage.removeItem('IOrderHeader');
    this.orderHeader = null;
    let steps = this.steps.getValue();
    steps[1][3] = '';
    steps[2][3] = 'disabled';
    this.steps.next(steps);
  }
  
  saveItemsData() {
    if (this.orderItems.length > 0) {
      localStorage.setItem('IOorderItems', JSON.stringify(this.orderItems));
      let steps = this.steps.getValue();
      steps[0][3] = 'completed';
      steps[1][3] = '';
      if (this.orderHeader != null) {
        steps[1][3] = 'completed';
        steps[2][3] = '';
      }        
      this.steps.next(steps);
    } else {
      localStorage.removeItem('IOorderItems');
      let steps = this.steps.getValue();
      steps[1][3] = '';
      steps[2][3] = 'disabled';
      this.steps.next(steps);
    }
    //console.log("savedData", localStorage.getItem('orderItems'));
    //console.log("orderItems", this.orderItems);
  }

  removeItemsData() {
    localStorage.removeItem('IOorderItems');
    let steps = this.steps.getValue();
      steps[0][3] = 'active';  
      steps[1][3] = 'disabled'
      steps[2][3] = 'disabled';
      this.steps.next(steps);    
  }

  private isItemInList(itemNumber: string): boolean {
    let result: boolean = false;

    if (this.orderItems.length > 0)
      this.orderItems.forEach(item => {
        if (item.itemNumber == itemNumber)
          result = true;
      });
    
    return result;
  }
  
  AddItem(newItem: Item) {
    if (!this.isItemInList(newItem.itemNumber)) {
      if (newItem.itemOrderAmount > 0) {
        var _newItem: Item = {
          itemName: newItem.itemName,
          itemNumber: newItem.itemNumber,
          itemStockAmoun: newItem.itemStockAmoun,
          itemOrderAmount: newItem.itemOrderAmount
        };
  
        this.orderItems.push(_newItem);
      }
    } else {
      let index = this.getItemIndex(newItem.itemNumber);
      if (((+this.orderItems[index].itemOrderAmount) + (+newItem.itemOrderAmount)) < this.orderItems[index].itemStockAmoun)
        this.orderItems[index].itemOrderAmount = (+this.orderItems[index].itemOrderAmount) + (+newItem.itemOrderAmount);
    }
    this.saveItemsData();
  }

  private getItemIndex(itemNumber: string): number {
    let result: number = -1;

    if(this.orderItems.length > 0)
      for(let i = 0; i < this.orderItems.length; i++) 
        if (this.orderItems[i].itemNumber == itemNumber)
          result = i;

    return result;
  }

  odRemoveItem(listItemRow: number) {
    this.orderItems.splice(listItemRow, 1);
    this.saveItemsData();
    //$("#"+listItemRow+"orderItem").remove();
  }

  onDecreaseItemAmount(listItem: Item) {
    if (listItem.itemOrderAmount > 0) {
      listItem.itemOrderAmount--;
      this.saveItemsData();
    }
  }

  onIncreaseItemAmount(listItem: Item) {
    if (listItem.itemOrderAmount < listItem.itemStockAmoun) {
      listItem.itemOrderAmount++;
      this.saveItemsData();
    }
  }

  onSearchChange(searchValue: string): void {  
    $("#shopItems tr").filter(function() {
      $(this).toggle($(this).text().indexOf(searchValue) > -1);
    });
  }

  newOrder(): void {
    this.removeHeaderData();
    this.removeItemsData();
    this.orderHeader = null;
    this.orderItems = [];
    //this.router.navigate(["/nova-objednavka/polozky"]);
  }
}
