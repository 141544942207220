import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { User } from '../auth/user';
import { InsertOrder, ListItem, ListOrder, returnON, WebOrder } from './types';
import { PacketFilter } from '../trivas-rest/types';
import { Item, Items } from '../list-of-items/list-item';

@Injectable({
  providedIn: 'root'
})
export class TrivasRestService {
  private items = new BehaviorSubject(null);

  private SQLServer: string = '92.62.228.244';
  private DBName: string;
  private UserName: string;
  private Password: string;

  constructor(private http: HttpClient, public auth: AuthService) {
    const user = this.auth.getUser();
    this.DBName = user.Database;
    this.UserName = user.UserName;
    this.Password = user.Password;
  }

  createOrder(newOrder: InsertOrder): Promise<boolean> {
    const request = JSON.stringify({
      SqlServer: this.SQLServer,
      DatabaseName: this.DBName,
      UserName: this.UserName,//userLogin.UserName,
      Password: this.Password,
      Order: newOrder
    });
    
    let promise: Promise<boolean> = new Promise((resolve, reject) => {
      this.http.post('https://trivas.rest:10042/insertorderofmydelivery', request).toPromise().then((result: any) => {
        if (result) {
          resolve(true);
        } else {
          console.error('Neúspěšné vytvoření objednávky: ', result);
          resolve(false);
        };
      });
    });

    return promise;
  }

  private async setItems(): Promise<void> {
    const userLogin = this.auth.getUser();
    const request = JSON.stringify({
      SqlServer: this.SQLServer,
      DatabaseName: this.DBName,
      UserName: this.UserName,//userLogin.UserName,
      Password: this.Password
    });

    this.http.post('https://trivas.rest:10041/itemsallwithstock', request).subscribe(response => {
      let data: Items = new Items(response);
      let items: Item[] = [];
      data.items.forEach(item => {
        items.push(item);
      });
      this.items.next(items);
    });
  }
  
  sendConfirmEmail(lib: string,num: string): Promise<boolean> {
    const request = JSON.stringify({
      SqlServer: this.SQLServer,
      DatabaseName: this.DBName,
      UserName: this.UserName,
      Password: this.Password,
      Order: {
        DocumentLibrary_999OS: lib,
        DocumentNumber_999OS: num
      }
    });

    let promise: Promise<boolean> = new Promise((resolve, reject) => {
      this.http.post('https://trivas.rest:10042/sendEmailByDNDL', request).toPromise().then((result: any) => {
        if (result) {
          resolve(result);
        } else {
          console.error('Neúspěšné odeslání emailu: ', result);
          reject(false);
        };
      });
    });
    
    return promise;
  }
  
  getItems(): Observable<Item[]> {
    this.setItems();
    return this.items.asObservable();
  }

  async returnNO(): Promise<returnON> {
    let userLogin = this.auth.getUser();
    
    const request = JSON.stringify({
      SqlServer: this.SQLServer,
      DatabaseName: this.DBName,
      UserName: this.UserName,//userLogin.UserName,
      Password: this.Password
    });

    let promise: Promise<returnON> = new Promise((resolve, reject) => {
      this.http.post('https://trivas.rest:10042/returnON', request).toPromise().then((result: returnON) => {
        if(result)
          resolve(result)
        else
          resolve(undefined);
      });
    });

    return promise;
  }
}
