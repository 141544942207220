import { ListItem } from "../trivas-rest/types";

export interface Item {
    itemNumber: string,
    itemName: string,
    itemStockAmoun: number,
    itemOrderAmount?: number
}

export class Items {
    items: Item[] = [];

    constructor(data: any) {
        data.forEach(ListItem => {
            let item: Item = {
                itemName: ListItem.NameItems_001,
                itemNumber: ListItem.Items_001,
                itemStockAmoun: (+ListItem.FreeAmount) > 0 ? +ListItem.FreeAmount : 0,
                itemOrderAmount: 0
            }

            this.items.push(item);
        });
    }
}